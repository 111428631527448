@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        counselor
Version:        1.0
Last change:    10.05.2016
Primary use:    Best Psychology & Counseling HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-counselor/table-of-content.less";

 
// Initialize Variables
@import "less-counselor/variables.less";
@import "less-counselor/mixins.less";
// Typography
@import "less-counselor/typography.less";

// Common Styles
@import "less-counselor/common.less";
@import "less-counselor/extra.less";
@import "less-counselor/overlay.less";

// Header
@import "less-counselor/header.less";

// Nav
@import "less-counselor/nav.less";

// Content Blocks
@import "less-counselor/topbar.less";
@import "less-counselor/inner-header-title.less";
@import "less-counselor/vertical-nav.less";
@import "less-counselor/menu-full-page.less";
@import "less-counselor/boxed-layout.less";
@import "less-counselor/form.less";
@import "less-counselor/side-push-panel.less";
@import "less-counselor/box-hover-effect.less";
@import "less-counselor/gallery-isotope.less";
@import "less-counselor/sliders.less";
@import "less-counselor/home.less";
@import "less-counselor/about.less";
@import "less-counselor/campaign.less";
@import "less-counselor/volunteer.less";
@import "less-counselor/contact.less";
@import "less-counselor/event.less";
@import "less-counselor/practice-area.less";
@import "less-counselor/services.less";
@import "less-counselor/job.less";
@import "less-counselor/shop.less";
@import "less-counselor/blog.less";

// Shortcodes
@import "less-counselor/shortcodes.less";


// Widgets
@import "less-counselor/widgets.less";


// Footer
@import "less-counselor/footer.less";